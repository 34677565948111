@import "../../../../../../lib/uio1/profile/css/util.less";

//@import url("//hello.myfonts.net/count/254a32");
//@import url("//hello.myfonts.net/count/254a33");
@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../fonts/254a32_4_0.eot");
  src: url("../fonts/254a32_4_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/254a32_4_0.woff") format("woff"),
    url("../fonts/254a32_4_0.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../fonts/254a32_5_0.eot");
  src: url("../fonts/254a32_5_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/254a32_5_0.woff") format("woff"),
    url("../fonts/254a32_5_0.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNova-Light";
  src: url("../fonts/254a33_2_0.eot");
  src: url("../fonts/254a33_2_0.eot?#iefix") format("embedded-opentype"),
    url("../fonts/254a33_2_0.woff") format("woff"),
    url("../fonts/254a33_2_0.ttf") format("truetype");
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

body {
  background-color: #000;
  color: #2b2b2b;
  font-family: Georgia, serif;
}
a {
  color: #2b2b2b !important;
  text-decoration: underline;
}
h1,
h2,
h3 {
  text-transform: uppercase !important;
  font-family: ProximaNova-Light;
  border: none;
  padding-top: 3px !important;
  border-top: 1px solid @color-dark;
  border-bottom: 1px solid @color-dark;
  font-weight: normal;
  font-style: normal;
  a {
    text-decoration: none;
    color: #2b2b2b !important;
  }
}
h1 {
  font-size: 2.8rem;
  display: block;
}
h2 {
  font-size: 20px;
}
p {
  font-size: 16px;
  line-height: 22px;
}
.vrtx-introduction p {
  font-size: 18px !important;
  font-style: italic;
  line-height: 22px;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: #404040 !important;
    color: @color-light !important;
    text-decoration: none;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-secondary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: #000; // url("../images/banner.png") no-repeat scroll right top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      font-family: "ProximaNova-Regular";
      background: transparent
        url("../images/head-dropdown-toggle-white.png")
        no-repeat scroll 95% 53%;
      background-size: 5%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 251px;
      .head-menu {
        right: 110px;
        top: 15px;
        .language {
          display: none;
        }
        a {
          color: @color-light !important;
          background-color: transparent;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-component .vrtx-dropdown-wrapper {
          top: 30px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        .language-link {
          position: absolute;
          right: 14px;
          bottom: -34px;
          color: @color-light !important;
          font-family: "ProximaNova-Regular";
          font-size: 13px;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
#globalnav {
  background: transparent none repeat scroll 0 0;
  ul {
    background: @color-primary none repeat scroll 0 0;
    li {
      a,
      &.vrtx-active-item a {
        font-family: ProximaNova-Light;
        font-size: 16px;
        padding: 6.5px 14px;
        text-transform: uppercase;
        color: @color-light !important;
        text-decoration: none;
        &:hover,
        &:focus {
          background: @color-secondary none repeat scroll 0 0;
          color: @color-dark !important;
          margin-top: 0;
        }
      }
      &.vrtx-active-item a {
        background: @color-secondary none repeat scroll 0 0;
        color: @color-neutral--dark !important;
      }
    }
  }
}
#main {
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  overflow: hidden;
  padding: 30px 20px 20px 20px;
  background: @color-light;
  a:hover {
    color: @color-link !important;
  }
  #vrtx-content {
    width: 100% !important;
    #vrtx-main-content {
      &.vrtx-empty-additional-content {
        //, &.vrtx-hide-additional-content-true, &:last-child {
        width: 507px;
      }
      #vrtx-main-content-1 .vrtx-box-content .vrtx-featured-item-1 {
        .vrtx-box-content {
          margin-top: 1px;
        }
        .vrtx-more {
          display: none !important;
        }
      }
      #vrtx-main-content-3 .vrtx-box-content p.image img {
        padding-right: 0px !important;
        vertical-align: middle;
      }
      #vrtx-featured-item-1 h2 {
        text-transform: none !important;
        border: none !important;
        font-family: ProximaNova-Semibold;
      }
    }
    #vrtx-additional-content {
      width: 220px;
      float: left;
      margin-left: 50px;
      .vrtx-frontpage-box h3,
      #vrtx-related-content h3 {
        font-size: 0.9em !important;
        line-height: 1.29em;
        border: none;
      }
      li {
        line-height: 22px;
        border-bottom: none !important;
      }
      a.vrtx-event-component-title,
      .vrtx-frontpage-box li a {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  .grid-container {
    background: transparent;
    .row {
      background: @color-light;
      .content-over-image-left .vrtx-box-content,
      .content-over-image-right .vrtx-box-content,
      .featured-one-big-two-column .vrtx-featured-item-1 .vrtx-box-content {
        top: 0px;
      }
      .vrtx-frontpage-box .vrtx-box-content .vrtx-featured-item-picture p  {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  #left-main ul.vrtx-breadcrumb-menu li {
    &.vrtx-parent a {
      text-transform: uppercase;
      font-family: ProximaNova-Light;
      font-size: 18px;
      text-decoration: none;
      background-color: transparent !important;
      &.vrtx-marked {
        text-decoration: none;
      }
      span {
        font-weight: bold;
        background-color: transparent !important;
      }
    }
    &.vrtx-ancestor:hover a:after {
      border-color: @color-neutral--air transparent transparent transparent;
    }
    &.vrtx-child {
      /*border-bottom: 1px @color-neutral--air solid;*/
      a {
        font-size: 1.5rem;
        color: @color-link;
        background-color: transparent !important;
        /*background-color: @color-neutral--light;*/
        border-top: none !important;
        background: rgba(0, 0, 0, 0)
          url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat
          scroll 10px 23px;
        text-decoration: none;
        &.vrtx-marked  {
          text-decoration: underline;
        }
      }
    }
    a {
      &.vrtx-marked {
        /*background-color: #1b1b1b !important;*/
        /*color: @color-light !important;*/
        border-top: none !important;
        text-decoration: underline;
        font-weight: bold;
      }
      &:hover,
      &:focus {
        /*background-color: @color-neutral--air;*/
        /*text-decoration: underline;*/
      }
    }
    span::before,
    a::before {
      content: " " !important;
    }
  }
  #right-main {
    margin-left: 0px;
    padding-left: 38px;
    .vrtx-frontpage-wide  {
      display: block !important;
    }
    #vrtx-content #vrtx-additional-content {
      float: right;
      width: 193px;
      margin-left: 0px;
      padding-left: 38px;
    }
  }
  .vrtx-event-component a.vrtx-event-component-title,
  .vrtx-feed ul li a.item-title,
  .vrtx-recent-comments ul li a.comments-title {
    font-weight: bold;
  }
  .vrtx-frontpage-box-picture {
    margin-top: 0px;
  }
  #vrtx-main-user {
    .vrtx-introduction {
      overflow: hidden;
      margin-bottom: 15px;
    }

    hr {
      border-bottom: 1px dotted #c1c1c1;
    }
    .vrtx-introduction:after {
      content: "................................................................................................................................................................................";
      color: #a6a6a6;
      letter-spacing: 3px;
      font-size: 7px;
      padding-top: 15px;
      display: inline-block;
    }
  }
}

.vrtx-frontpage-full-width.total-main #main {
  width: 970px;
  margin: auto;
  #total-main {
    overflow: hidden;
    #vrtx-content {
      display: block;
      #vrtx-change-language-link,
      #hdr {
        display: block;
        width: 100% !important;
        line-height: 40px !important;
      }
      #vrtx-change-language-link {
        margin-top: 0px;
      }
    }
  }
}
table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}
div.vrtx-more a,
a.all-messages,
a.more-url,
.vrtx-frontpage-box a.more,
#vrtx-additional-content a.more,
#vrtx-additional-content a.all-messages {
  font-family: ProximaNova-Semibold;
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 400;
  background-size: 1.7em, 1.3em !important;
}

#main .vrtx-back a:before {
  background: url(../images/icon-chevron-back-yellow.svg) 0 0.2ex no-repeat;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  width: 1.84em;
  background: url(../images/y-arrow-small.png) no-repeat bottom left !important;
  font-family: ProximaNova-Semibold;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-yellow.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-yellow.svg");
  }
}
#vrtx-change-language-link {
  line-height: 47px;
}
#main .vrtx-subscribe-component.vrtx-dropdown-component {
  float: right;
  padding-bottom: 20px;
  a {
    font-weight: bold;
    text-decoration: none;
    background: url(../images/icon-chevron-down-yellow.svg) no-repeat right
      center;
    padding-right: 30px !important;
  }
  .vrtx-dropdown-wrapper {
    position: relative;
    top: 0px;
    li {
      a {
        background: transparent;
        padding: 10px;
        font-size: 14px;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
//add borders
.vrtx-feed li,
.vrtx-resource.vrtx-default-article,
.vrtx-resource.vevent {
  border-bottom: 1px dotted #c1c1c1 !important;
}
.vrtx-resource.vevent {
  padding-bottom: 20px !important;
}
.vrtx-feed li {
  display: block;
  padding-bottom: 10px;
  &:last-of-type {
    border-bottom: none;
  }
}
#main .vrtx-paging-feed-wrapper,
.vrtx-date-info {
  border-top: 1px solid #eaeaea !important;
}
//remove borders
#vrtx-event-listing #main .vrtx-resources h2:first-child,
.vrtx-resource:last-child {
  border: none !important;
}

#footer-wrapper
  #footers
  a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link).hover,
#footer-wrapper
  #footers
  a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link):focus,
#footer-wrapper
  #footers
  a:not(.vrtx-dropdown-form-link):not(.vrtx-dropdown-link):hover,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*="button"]).hover,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*="button"]).hover,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*="button"]).hover,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > :not(ul):not(ol)
  a:not([class*="button"]).hover,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > :not(ul):not(ol)
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > :not(ul):not(ol)
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]).hover,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ol:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*="button"]).hover,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*="button"]).hover,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*="button"]):focus,
body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  strong.elm-has-own-text
  a:not([class*="button"]).hover,
body:not(#vrtx-frontpage)
  strong.elm-has-own-text
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  strong.elm-has-own-text
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  td.elm-has-own-text:not([class*="person-listing"])
  a:not([class*="button"]).hover,
body:not(#vrtx-frontpage)
  td.elm-has-own-text:not([class*="person-listing"])
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  td.elm-has-own-text:not([class*="person-listing"])
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > :not(ul):not(ol)
  a:not([class*="button"]).hover,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > :not(ul):not(ol)
  a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > :not(ul):not(ol)
  a:not([class*="button"]):hover,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]).hover,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):focus,
body:not(#vrtx-frontpage)
  ul:not([class^="vrtx-tags-"])
  li.elm-has-own-text
  > a:not([class*="button"]):hover {
  background: transparent !important;
  border: none !important;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-decoration: underline;
  color: @color-link !important;
}
//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-primary;
    margin-bottom: 20px;
    padding: 0px !important;
    #footers {
      background: @color-light !important;
      padding: 15px 20px 20px 20px;
      position: relative;
      .col-3-5,
      .col-2-5 {
        border-top: 2px solid #000;
      }
      h2.menu-label,
      span.menu-label {
        color: #7e7e7e !important;
        font-family: ProximaNova-Light;
        border: none;
        font-weight: bold;
        text-transform: inherit !important;
      }
      .web-info {
        width: 40%;
        height: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        span {
          display: inline-block;
          margin-left: 86px;
          margin-bottom: 40px;
          color: #777;
          a {
            color: #000;
          }
        }
      }
    }
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0)
        url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #nav-offcanvas ul li a {
      color: @color-light !important;
      text-decoration: none;
      font-family: "ProximaNova-Regular";
      font-weight: 400;
    }
    #head-wrapper {
      background: none @color-light;
      font-family: "ProximaNova-Regular";
      #head {
        height: 150px;
        #header {
          a.logo {
            height: 150px;
            display: block;
            background: url(../images/logo-no.jpg) no-repeat 0 0;
            background-size: auto 100%;
            background-position: 56%;
            img {
              display: none;
            }
          }
          .language-link {
            display: none;
            /*top: 0px;
            right: 15px;
            font-size: 13px;
            color: #b0b0b0 !important;
            text-decoration: underline;*/
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  #toggle-search,
  #toggle-navigation {
    span.text {
      color: @color-light !important;
    }
  }
  .menu-search {
    input[type="text"] {
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-frontpage #main {
    padding: 30px 20px 20px 20px !important;
    width: 100% !important;
    margin: 0px;
  }
  #main .grid-container {
    border-top: none !important;
  }
  #main .vrtx-frontpage-box {
    border-top: none !important;
  }
  #main #vrtx-main-content #vrtx-main-content-1 {
    .vrtx-featured-item-picture .vrtx-featured-item-picture {
      display: none;
    }
    .vrtx-box-content .vrtx-box-content {
      top: 0px;
      right: 0px;
      padding: 15px;
      background: #fff;
      width: 100%;
      margin-left: 0;
      .vrtx-box-sub-content {
        display: block;
      }
    }
    .vrtx-featured-item.vrtx-featured-item-2,
    .vrtx-featured-item.vrtx-featured-item-3 {
      padding: 0px;
    }
  }
  #main #right-main {
    padding-left: 0px;
  }
  #main #vrtx-content #vrtx-additional-content {
    width: 100% !important;
    margin-left: 0px;
    .vrtx-frontpage-box {
      border-bottom: none;
    }
  }
  #vrtx-change-language-link {
    top: -8px !important;
    font-size: 15px;
  }
  #footer-wrapper #footers {
    padding-top: 20px !important;
    .col-3-5 {
      padding-top: 20px;
    }
    .col-2-5 {
      border-top: none !important;
    }
    .web-info {
      width: 100% !important;
      position: relative !important;
      text-align: left;
      span {
        margin: 0px !important;
      }
    }
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary : #000;
@color-primary--dark : darken(spin(@color-primary, -5), 10%);
@color-secondary : #fff200;
@color-secondary--dark : darken(spin(@color-secondary, -5), 10%);
@color-link : #384b94;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #dcdcdc;
@color-neutral--air : #ececec;
@color-lines : #e4e4e4;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #000000;
@footer-color : @color-neutral--light;

// Spacing
@unit : 5px;
@spacing--xlarge : 100px;
@spacing--large : 40px;
@spacing : 20px;
@spacing--small : 10px;
